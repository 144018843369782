import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import countryList from "../demo-data/countries.json";
import { useState } from "react";
import {
  getTags,
  getTeacherApplication,
  TeacherSignUp,
} from "../redux/actions/AuthAction";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import { useSnackbar } from "react-simple-snackbar";
import otherLanguages from "../demo-data/languages.json";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";
import { Controller } from "react-hook-form";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://clapingo.com/"
        style={{ cursor: "pointer" }}
      >
        Clapingo
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const history = useHistory();
  const [openSnackbar] = useSnackbar();
  const { applicationId } = useParams();
  const [applicationData, setApplicationData] = useState({});
  const [country, setCountry] = useState("India");
  const [timezone, setTimezone] = useState("Asia/Kolkata");
  const [skillTags, setSkillTags] = useState([]);
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [error, setError] = useState({
    tags: false,
    image: false,
    languages: false,
  });
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});

  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      countryCode: "",
      phoneNumber: applicationData.phone,
      email: applicationData.email,
      name: applicationData.fullName,
      isDemo: true,
      isSession: true,
      isKidDemo: false,
      isKidSession: false,
      country: "",
      timezone: "",
      tags: "",
      subject: [
        {
          subject: "English",
          disabled: false,
          adults: true,
          kids: false,
        },
      ],
      type: "",
      panNumber: "",
      languages: "",
      image: "",
    },
  });

  useEffect(() => {
    if (applicationId) {
      const onSuccess = (data) => {
        setApplicationData(data);
        reset({
          countryCode: "",
          phoneNumber: data.phone,
          email: data.email,
          name: data.fullName,
          isDemo: true,
          isSession: true,
          isKidDemo: false,
          isKidSession: false,
          country: "",
          timezone: "",
          tags: "",
          subject: [
            {
              subject: "English",
              disabled: false,
              adults: true,
              kids: false,
            },
          ],
          type: "",
          panNumber: "",
          languages: "",
          image: "",
        });
      };

      const onError = (msg) => {
        openSnackbar("The link is broken, please check the link");
        console.log(msg);
      };
      getTeacherApplication(applicationId, onSuccess, onError);
    } else {
      openSnackbar("Applicant Id not found, please check the link");
    }
  }, [reset]);

  const checkErrors = () => {
    !skills[0] &&
      setError((prevState) => ({
        ...prevState,
        tags: true,
      }));

    !image &&
      setError((prevState) => ({
        ...prevState,
        image: true,
      }));
    !languages[0] &&
      setError((prevState) => ({
        ...prevState,
        languages: true,
      }));
  };

  useEffect(() => {
    if (skills[0]) {
      setError((prevState) => ({
        ...prevState,
        tags: false,
      }));
    }
    if (image) {
      setError((prevState) => ({
        ...prevState,
        image: false,
      }));
    }
    if (languages[0]) {
      setError((prevState) => ({
        ...prevState,
        languages: false,
      }));
    }
  }, [skills, image, languages]);

  const handelImage = (event) => {
    var file = event.target.files[0];
    var url = URL.createObjectURL(file);
    setImage(url);
    setFile(file);
  };

  const fileUpload = (data) => {
    const config = {
      bucketName: `${process.env.REACT_APP_BUCKET_NAME}`,
      dirName: "Images",
      region: "ap-south-1",
      accessKeyId: `${process.env.REACT_APP_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_SECRET_ACCESS_KEY}`,
    };

    S3FileUpload.uploadFile(file, config)
      .then((res) => {
        openSnackbar("File uploaded successfully");
        data["countryCode"] = country;
        data["country"] = country;
        data["timezone"] = timezone;
        data["tags"] = skills;
        data["languages"] = languages;
        data["image"] = res.location;

        var onSuccess = (data) => {
          console.log(data.newTeacher);
          localStorage.setItem("TEACHER", JSON.stringify(data.teacher));
          localStorage.setItem("TOKEN", JSON.stringify(data.token));
          openSnackbar(data.message);
          reset();
          setTimeout(() => {
            history.push("/profile");
          }, 1000);
          setLoading(false);
        };

        var onError = (msg) => {
          openSnackbar(msg);
          setLoading(false);
        };
        TeacherSignUp(data, onSuccess, onError);
      })
      .catch((err) => {
        console.log(err);
        openSnackbar("Something went wrong!");
      });
  };

  const onSubmit = (data) => {
    if (!error.tags && !error.image && !error.languages) {
      setLoading(true);
      fileUpload(data);
    }
  };

  useEffect(() => {
    const onSuccess = (data) => {
      setSkillTags(data.tags);
    };

    const onError = (msg) => {
      console.log(msg);
    };
    getTags(onSuccess, onError);
  }, []);

  const handleButtonClick = () => {
    const fileInput = document.getElementById("choose_file");
    fileInput.click();
  };

  const boxSX = {
    minHeight: 100,
    p: 2,
    border: "1px dashed #A9A9A9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "#FAFAFA",
    borderRadius: "10px",
    my: 1,
    "&:hover": {
      borderColor: "#1976D2",
    },
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Clapingo Speaker Sign Up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                id="name"
                label="Name"
                name="name"
                error={errors.name ? true : false}
                helperText={
                  errors.name?.type === "required" && "Full Name is required"
                }
                {...register("name", { required: true })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                id="email"
                label="Email Address"
                name="email"
                error={errors.email ? true : false}
                helperText={
                  (errors.email?.type === "required" && "Email is required") ||
                  (errors.email?.type === "pattern" &&
                    "Email format is incorrect")
                }
                {...register("email", {
                  required: true,
                  pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                })}
              />
              <TextField
                margin="normal"
                disabled
                required
                fullWidth
                label="Phone Number"
                InputLabelProps={{ shrink: true }}
                id="phoneNumber"
                error={errors.phoneNumber ? true : false}
                helperText={
                  errors.phoneNumber?.type === "required" &&
                  "Phone Number is required"
                }
                {...register("phoneNumber", { required: true })}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="panNumber"
                label="PAN Number"
                id="panNumber"
                error={errors.panNumber ? true : false}
                helperText={
                  (errors.panNumber?.type === "required" &&
                    "PAN Number is required") ||
                  (errors.panNumber?.type === "minLength" &&
                    "PAN Number should be 10 characters") ||
                  (errors.panNumber?.type === "maxLength" &&
                    "PAN Number should be 10 characters")
                }
                {...register("panNumber", {
                  required: true,
                  minLength: 10,
                  maxLength: 10,
                })}
              />
              <Autocomplete
                sx={{ my: 1 }}
                multiple
                id="tags-readOnly"
                options={otherLanguages.map((language) => language.name)}
                onChange={(event, newValue) => {
                  setLanguages(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Other Languages"
                    placeholder="Choose language"
                  />
                )}
              />
              {error.languages && (
                <FormHelperText sx={{ color: "#d32f2f", ml: 2 }}>
                  Language(s) are required
                </FormHelperText>
              )}
              <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={country}
                  label="Country"
                  required
                  onChange={(e) => setCountry(e.target.value)}
                  error={error.country}
                >
                  {countryList.map((country, index) => {
                    return (
                      <MenuItem key={index} value={country.name}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Timezone
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={timezone}
                  label="Timezone"
                  required
                  onChange={(e) => setTimezone(e.target.value)}
                  error={error.timezone}
                >
                  {countryList.map((country, index) => {
                    return (
                      <MenuItem key={index} value={country.timezones[0]}>
                        {country.timezones[0]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Autocomplete
                sx={{ my: 1 }}
                multiple
                id="tags-readOnly"
                options={skillTags}
                onChange={(event, newValue) => {
                  setSkills(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Add Skills"
                    placeholder="Skills"
                  />
                )}
              />
              {error.tags && (
                <FormHelperText sx={{ color: "#d32f2f", ml: 2 }}>
                  Skills are required
                </FormHelperText>
              )}
              <FormControl margin="normal" fullWidth id="type">
                <InputLabel
                  id="demo-simple-select-label"
                  error={errors.type ? true : false}
                >
                  Type
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      labelId="demo-simple-select-label"
                      label="Type"
                      {...register("type", { required: true })}
                      error={errors.type ? true : false}
                      {...field}
                    >
                      <MenuItem value="Teacher" id="type">
                        Teacher
                      </MenuItem>
                      <MenuItem value="Creator" id="type">
                        Creator
                      </MenuItem>
                    </Select>
                  )}
                  name="type"
                  control={control}
                />
                {errors.type && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    Type is required
                  </FormHelperText>
                )}
              </FormControl>
              <Box sx={boxSX} onClick={handleButtonClick}>
                {image ? (
                  <img
                    src={image}
                    alt="profile-picture"
                    height="150"
                    width="150"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <img
                    src="/assets/upload.png"
                    alt="upload"
                    height="50"
                    width="50"
                  />
                )}

                <Box sx={{ mt: 1 }}>Click to select profile picture</Box>
              </Box>
              <label htmlFor="choose_file">
                <input
                  type="file"
                  onChange={handelImage}
                  id={`choose_file`}
                  name={`choose_file`}
                  accept="image/*"
                  style={{ display: "none" }}
                />
              </label>
              {error.image && (
                <FormHelperText sx={{ color: "#d32f2f", ml: 2 }}>
                  Profile Picture is required
                </FormHelperText>
              )}
              <LoadingButton
                fullWidth
                endIcon={<SendIcon />}
                sx={{ mt: 3, mb: 2 }}
                loading={loading}
                loadingPosition="end"
                variant="contained"
                type="submit"
                onClick={checkErrors}
              >
                <span>Submit</span>
              </LoadingButton>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                >
                  <Link href="/signin" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
