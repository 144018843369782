import { Container } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const PdfViewerPage = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const documentLink = query.get("doc_url");

  // console.log("document", encodeURI(documentLink))

  return (
    <Container
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        padding: "10px",
        margin: 0,
      }}
    >
      {documentLink ? (
        <iframe
          src={`${encodeURI(documentLink)}#toolbar=0`} //Hides toolbar (download, print etc.)
          title="PDF Viewer"
          style={{
            width: "80vw",
            height: "80vh",
            border: "none",
          }}
        />
      ) : (
        <h1 style={{ textAlign: "center", color: "red" }}>No PDF Found</h1>
      )}
    </Container>
  );
};

export default PdfViewerPage;

